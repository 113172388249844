import * as React from "react";

import Breadcrumbs from '../components/Breadcrumbs';
import Link from '../components/Link';
import List from '../components/List';
import Seo from '../components/Seo';

// markup
const PolicyPage = ({ pageContext }) => {
  return (
    <>
      <Seo
        title="Політика конфіденційності"
        descriptions="Політика конфіденційності сайту проєкту `Українська народна творчість`, загальні положення, умови використання та мета збору персональних даних, надання доступу до інформації та захист інформації."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg">
          <div className="row items-center">
            <div className="col-full md:col-6 py-10 md:py-24">
              <Breadcrumbs
                crumbs={pageContext.breadcrumb.crumbs}
                crumbLabel="Політика конфіденційності"
              />
              <h1 className="typo-h1 max-w-sm lowercase mt-4">
                Політика конфіденційності
              </h1>
            </div>
            <div className="col-full md:col-6">
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          1. Загальні положення
        </h2>
        <p className="typo-body mt-4">
          Згідно Закону України від 01 червня 2010 року № 2297-VI «Про захист персональних даних» (надалі – «Закон») під
          персональними даними розуміють відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або
          може бути конкретно ідентифікована. Згідно Політики конфіденційності в поняття "особистої інформації" входить
          інформація, або частина інформації, котра дозволяє Вас ідентифікувати. Як правило сюди входить ім’я та
          прізвище, псевдонім (нік), адреса електронної пошти та номер телефону, може також входити така інформація, як
          IP-адреса. Такі відомості ми можемо отримати безпосередньо від Вас, наприклад, при реєстрації на сайті,
          наданні контактних даних, інформації отриманої із банківської картки або платежів, а також іншу особисту
          інформацію, яку Ви добровільно надали нам.
        </p>
        <p className="typo-body mt-4">
          Організатори проєкту з великою повагою ставиться до конфіденційної (персональної) інформації всіх без винятку
          осіб, які відвідали сайт, а також тих, хто користується наданими сайтом послугами; в зв'язку з чим,
          організатори проєкту прагнуть захищати конфіденційність персональних даних (відомостей чи сукупність
          відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована), тим самим створивши
          і забезпечивши максимально комфортні умови використання сервісів Сайту кожному користувачеві.
        </p>
        <h2 className="typo-h2 mt-8">
          2. Використання та мета збору персональних даних.
        </h2>
        <p className="typo-body mt-4">
          Особиста інформація відвідувачів сайту, зібрана при реєстрації, переважно використовується для надання послуг
          у відповідності з Вашими потребами, в першу чергу для надання послуг або у договірних відносинах, а також для
          відповідей на Ваші запитання. Також ми можемо використовувати Вашу контактну інформацію в поштових
          відправленнях, а саме повідомляти Вас про нові можливості, акції та інші новини. Ми діємо відповідно до цієї
          Політики конфіденційності, на підставі Положення про обробку і захист персональних даних та на підставі
          чинного законодавства України.
        </p>
        <p className="typo-body mt-4">
          Ми маємо право зберігати Персональні дані стільки, скільки необхідно для реалізації мети, що зазначена у даній
          Політиці або у строки, встановлені чинним законодавством України або до моменту видалення Вами цих даних.
        </p>
        <h2 className="typo-h2 mt-8">
          3. Надання доступу до інформації.
        </h2>
        <p className="typo-body mt-6">
          Надання доступу до особистої інформації іншим компаніям і приватним особам можливе лише за таких наступних
          обставин:
        </p>
        <List className="typo-body mt-6">
          <List.Item>
            Має місце наявність дозволу Користувача. Для передачі будь-якої конфіденційної інформації потрібна явна
            згода Користувача.
          </List.Item>
          <List.Item>
            В випадках визначених чинним законодавством України, а також у випадках запобігання злочину або завдання
            шкоди нам або третім особам.
          </List.Item>
        </List>
        <h2 className="typo-h2 mt-8">
          4. Захист інформації.
        </h2>
        <p className="typo-body mt-6">
          Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи
          знищення. До цих заходів належать, зокрема, внутрішня перевірка процесів збору, зберігання та обробки даних і
          заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для
          запобігання неавторизованого доступу до систем, в яких ми зберігаємо особисті дані.
        </p>
        <h2 className="typo-h2 mt-8">
          5. Зміна політики конфіденційності.
        </h2>
        <p className="typo-body mt-6">
          Користувач приймає умови цієї політики конфіденційності і враховує, що дана політика конфіденційності може
          час від часу змінюватися. Зміни, що вносяться до політики конфіденційності, публікуються на цій сторінці.
        </p>
        <p className="mt-6">
          З питань щодо політики конфіденційності (в т.ч. видалення персональних даних), Користувач може зв'язатися з нами використовуючи e-mail:
          {' '}
          <Link to="mailto:contact@folklore.com.ua" className="text-red-500">
            contact@folklore.com.ua
          </Link>
        </p>
      </div>
    </>
  );
};

export default PolicyPage;
